import {Objective as ObjectiveObject} from "@/typescript/types";

class ObjectiveFilterService{

  filterObjectiveList(column: string, value: any, objectivesList: ObjectiveObject[]): ObjectiveObject[]{
    return objectivesList.filter((objective: ObjectiveObject) => {
      const parts = column.split( "." );

      parts.forEach(function (value: string) {

        if(objective!=null){
          // @ts-ignore
          objective = objective[value];
        }

      });

      if (value === 'all') {
        return objective;
      }
      // @ts-ignore
      return objective === value;
    });
  }

  filterObjectionOnDate(column: string, startDate: any, endDate: any, objectivesList: ObjectiveObject[]): ObjectiveObject[]{
    return objectivesList.filter((objective: ObjectiveObject) => {
      const parts = column.split( "." );
      parts.forEach(function (value: string) {
        // @ts-ignore
        objective = objective[value];
      });

      // @ts-ignore
      const start = new Date(objective);
      return start >= startDate && start <= endDate;
    });
  }


  filterObjectiveFromArray(arrayColumn: string,element: string, value: any, objectivesList: ObjectiveObject[]): ObjectiveObject[]{

    return objectivesList.filter((objective: ObjectiveObject) => {
      const parts = arrayColumn.split( "." );

      parts.forEach(function (value: string) {
        //@ts-ignore
        objective = objective[value]
      });

      if (value === 'all') {
        return objective;
      }
      //@ts-ignore
      if(objective.find((ele: any) => ele[element] == value)){

        return objective;
      }
      // @ts-ignore
      return objective === value;
    });
  }

}

export default new ObjectiveFilterService();
