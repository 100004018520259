






































import {Component, Vue} from "vue-property-decorator";
import SmartDateTime from "@/components/general/SmartDateTime.vue";

@Component({
  components: {SmartDateTime}
})

export default class SetScheduledObjective extends Vue {


  openDatePicker() {
    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless ',
      parent: this,
      props: {
        'minDate': null
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
          if (value) {
            this.$emit('duedate', value);
            //@ts-ignore
            this.$parent.close()
          }
        }
      },
    });
  }
}
