













































































































import { Component, Vue } from 'vue-property-decorator';
import BoardMembers from "@/components/board/BoardMembers.vue";
import ConfettiCanon from '@/components/general/ConfettiCanon.vue';

interface GuideItem {
  id: number;
  title: string;
  items: ContentItem[];
}

interface ContentItem {
  id: number;
  title: string;
  content: string;
}

@Component({
  components: {ConfettiCanon},
})
export default class OnboardingGuide extends Vue {
  // Current indices
  currentSectionIndex: number = 0;
  currentItemIndex: number = 0;
  progressType: string = 'is-dark';
  popConfetti: boolean = false;
  animate: string = 'slide';

  // Define the guide items
  guideItems: GuideItem[] = [
    {
      id: 1,
      title: 'Timeline',
      items: [
        {
          id: 11,
          title: 'Drag and drop to schedule your goals 🎯',
          content:
            "Give your future self a running start! Use the timeline to plan and structure your projects.",
        },
        {
          id: 12,
          title: 'Simply focus on the most relevant tasks 🗓️',
          content:
            "By dragging goals to future periods the todo-list will always show you the most important tasks to focus on.",
        },
        // {
        //   id: 13,
        //   title: 'Timeline 3  drop to scehdule goals in the timeline drop to scehdule goals in the timeline',
        //   content:
        //     "333 Timeline: After your head start - now it's time to schedule your goals in the timeline.",
        // },
      ],
    },
    {
      id: 2,
      title: 'Member Stuff',
      items: [
        {
          id: 21,
          title: 'Pembio has powerful collaborative features 🤝',
          content: "Chat, share files, sync progress and assign work to supercharge your productivity.  ",
        },
        // {
        //   id: 22,
        //   title: 'Member 2',
        //   content: "2222222 Member: Schedule your goals in the timeline.",
        // },
        // {
        //   id: 23,
        //   title: 'Member 3',
        //   content:
        //     "333 Member: After your head start - now it's time to schedule your goals in the timeline.",
        // },
      ],
    },
    {
      id: 3,
      title: 'Premium Stuff ',
      items: [
        {
          id: 31,
          title: 'Reach your full potential with Premium ✨',
          content: "Unlimited rooms, file manager, 24/7 support and much, much more",
        },
        // {
        //   id: 32,
        //   title: 'Premium 2',
        //   content: "2222222 Premium: Premium features to enhance your scheduling.",
        // },
        // {
        //   id: 33,
        //   title: 'Premium 3',
        //   content: "333 Premium: Unlock all premium features.",
        // },
      ],
    },
  ];

  // Computed properties to get current title and content
  get currentSection(): GuideItem {
    return this.guideItems[this.currentSectionIndex];
  }

  get currentItem(): ContentItem {
    return this.currentSection.items[this.currentItemIndex];
  }

  get currentTitle(): string {
    return this.currentItem.title;
  }

  get currentContent(): string {
    return this.currentItem.content;
  }

  // Navigation button states
  get canGoBack(): boolean {
    return this.currentItemIndex > 0;
  }

  get canGoForward(): boolean {
    return this.currentItemIndex < this.currentSection.items.length - 1;
  }

  get canGoPrevious(): boolean {
    return this.currentSectionIndex > 0;
  }

  get canGoNext(): boolean {
    return this.currentSectionIndex < this.guideItems.length - 1;
  }

  get onboarding() {
    return this.$store.state.expanded_onboarding;
  }

  get showIndicator(): boolean {
    return this.currentSection.items.length > 1 && this.currentSectionIndex === 0;
  }

  // Navigation methods
  goBack() {
    if (this.canGoBack) {
      this.currentItemIndex--;
    }
  }

  goForward() {
    if (this.canGoForward) {
      this.currentItemIndex++;
    }
  }

  goPrevious() {
    if (this.canGoPrevious) {
      this.currentSectionIndex--;
      this.currentItemIndex = 0;
    }
  }

  goNext() {
    if (this.canGoNext) {
      this.currentSectionIndex++;
      this.currentItemIndex = 0;
    }
  }

  getImageSrc(itemId: number): string {
    // Map item IDs to image filenames
    const imageMap: { [key: number]: string } = {
      11: require('@/assets/images/onboarding_guide1.jpeg'),
      12: require('@/assets/images/onboarding_guide2.jpeg'),
      21: require('@/assets/images/onboarding_guide3.jpeg'),
      22: require('@/assets/images/onboarding_guide3.jpeg'),
      23: require('@/assets/images/onboarding_guide3.jpeg'),
      31: require('@/assets/images/onboarding_guide4.jpeg'),
      32: require('@/assets/images/onboarding_guide4.jpeg'),
      33: require('@/assets/images/onboarding_guide4.jpeg'),
    };

    return imageMap[itemId] || require('@/assets/images/onboarding_guide1.jpeg');
  }

  // Optional: Handle edge cases or reset
  resetGuide() {
    this.currentSectionIndex = 0;
    this.currentItemIndex = 0;
  }

  handleUpgradeClick() {
    this.$router.push({name: 'workspace-checkout', params: {productId: "648f3a46-d5cc-48da-8a3c-a0c4e0f50a87", billingPeriod: "monthly"}});
  }

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
      props: {
        fromMemberInviteClick: true
      }
    }); 
  }

  handleCloseGuide() {
    if(this.onboarding === 1) {
      this.$store.commit('set_show_checklist_prompt', true);
      this.$store.commit('set_expanded_onboarding', 2);
      this.popConfetti = true;
      setTimeout(() => {
        this.popConfetti = false;
      }, 2000); 

    }
    this.$store.commit('set_display_onboarding_guide', false);
  }

  mounted() {
    // Load the initial content
    this.resetGuide();

    this.animate = 'fade'
    setTimeout(() => {
      this.animate = 'slide';
    }, 1000); 

  }
}
