





import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class RealTimeClock extends Vue {
  interval: any | null = null;
  time: string | null = '--:--';

  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      }).format()
    }, 1000)
  }

  beforeDestroy() {
    clearInterval(this.interval)
  }
}
