










import {Component, Vue, Watch} from 'vue-property-decorator';
import {UpdateBoardObjectives} from "@/graphql/board";
import {Objective as ObjectiveObject, ObjectivePaginator,} from "@/typescript/types";
import {ObjectivesListQuery, ObjectiveUpdatedSubscription} from "@/graphql/objective";
import sortBy from "lodash/sortBy";
import ObjectiveFilterService from "@/ObjectiveFilterService";
import pick from "lodash/pick";
import {isThisYear, isThisWeek, endOfToday, addDays, nextFriday, isFriday, nextSunday} from "date-fns";
import SetScheduledObjective from "@/components/board/objective/SetScheduledObjective.vue";
import ObjectiveCategoryService from "@/ObjectiveCategoryService";
import GuidedCreateObjective from "@/components/board/objective/GuidedCreateObjective.vue";
import PusherService from "@/PusherService";
import ObjectiveDetailed from "@/components/board/objective/ObjectiveDetailed.vue";
import Schedule from "@/components/objectives/Schedule.vue";
import BoardSettingsDropdown from "@/components/board/utilities/BoardSettingsDropdown.vue";
import BoardBookmarks from "@/components/board/utilities/BoardBookmarks.vue";
import BoardNavigator from "@/components/board/BoardNavigator.vue";
import BoardGoals from "@/components/board/utilities/BoardGoals.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";
import WorkableItem from "@/components/general/WorkableItem.vue";
import BoardChatIcon from "@/components/navigation/BoardChatIcon.vue";
import Template from "@/components/workspace/Template.vue";
import CompletedObjectives from "@/components/objectives/CompletedObjectives.vue";
import Chat from "@/components/chat/Chat.vue";
import RealTimeClock from "@/components/general/RealTimeClock.vue";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";




@Component({
  components: {
    WorkspacePage,
    RealTimeClock,
    Chat,
    CompletedObjectives,
    Template,
    BoardChatIcon,
    WorkableItem,
    BoardGoals,
    BoardNavigator,
    BoardBookmarks,
    GuidedCreateObjective,
    BoardSettingsDropdown,
    SetScheduledObjective,
    Schedule
  },
  apollo: {
    getObjectivesLists: {
      query: ObjectivesListQuery,
      fetchPolicy: 'network-only',
      //pollInterval: 10000,
      skip() {
        return !this.board
      },
      variables() {
        return {
          filter_type: 'board',
          filter_type_id: this.board.id,
          filter_type_category: 'ongoing',
          status: 'not_completed',
          //page: this.ongoingPagination
        }
      },
      result(result) {
       
        if (result && result.data && result.data.getObjectivesLists) {
          this.$events.fire('objective-loaded', true);
          result.data.getObjectivesLists.data.forEach((objective: ObjectiveObject) => {
            this.addObjectiveToList(objective)
          })
          this.$events.fire('has-more-pages-in-objective-ongoing', {category: 'ongoing', hasMorePages: result.data.getObjectivesLists.paginatorInfo.hasMorePages})
        }
      },
      subscribeToMore: {
        document: ObjectiveUpdatedSubscription,
        variables() {
          return {
            board_id: this.board.id,
          };
        },
        skip() {
          return !this.board
        },
        updateQuery(_previous, {subscriptionData}) {
          if (subscriptionData.data) {
            let updatedObjective = subscriptionData.data.objectiveUpdated;
            this.addObjectiveToList(updatedObjective);
          }
        }
      }
    }
  }
})
export default class Board extends Vue {
  objectivesList: ObjectiveObject[] = [];
  getObjectivesLists: ObjectivePaginator | null = null;
  todaysObjectivesList: ObjectiveObject[] = [];
  ongoingPagination = 1;
  upcommingPagination = 1;
  duePagination = 1;


  /**
   * Filter props
   */
  statusSelected: string = 'all';
  ownerSelected: string = 'all';
  prioritySelected: string = 'all';
  objectiveTypeSelected: string = 'all';
  objectiveTagSelected: string = 'all';
  recurringSelected: boolean = false;
  startDateSelected: Date | null = null;
  endDateSelected: Date | null = null;

  currentlyDragging: boolean = false;
  mainView: string = 'goals';

  startDateToday: Date = this.todayTime;
  dueDateWeek: Date = this.fridayDate;
  dueDateUnScheduled: Date | null = null;
  dueDateScheduled: Date = addDays(new Date(), 30);

  isMember: boolean = true;
  isAllObjectiveLoaded: boolean = false;

  currentTab: string | null = null;

  dragInDateRange: boolean = false;
  presenceActive: boolean = false;
  currentObjectiveId: string | null = null;

  get boardPid() {
    return this.$store.state.active_board_pid;
  }

  get board() {
    return this.$store.state.board;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get me() {
    return this.$store.state.me;
  }

  get roomBackground() {
    // todo: import the fallback
    if(this.board.backgroundImage) {
      return this.getBackgroundLink(this.board.backgroundImage.url)
    } else {
      return 'https://vapor-pembio-eu-bucket-staging.s3.eu-central-1.amazonaws.com/background-images/1652434876-BG-c90e657810a5dab21851386c62d3aed87c357550';
    }
  }

  getBackgroundLink(url: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path['background'] + url;
  }

  get todayTime() {
    let now = new Date();
    let d = new Date();
    d.setHours(17,0,0);

    if(now.getTime() < d.getTime()){
      return d;
    }else{
      return endOfToday();
    }
  }

  get fridayDate(){
    if(isFriday(new Date())){
      return nextSunday(new Date().setHours(23,59,59));
    }
    return nextFriday(new Date().setHours(17,0,0));
  }

  get today() {
    return new Date();
  }

  get keymap() {
    return {
      // 'esc+ctrl' is OK.  - OBSERVE: don't add parenthesis at the end of the function triggered.. This messes things up.
      'meta+a': this.openSpotlight,
      'meta+k': this.openPresence,
    }
  }

  openPresence() {
    this.presenceActive = !this.presenceActive;

    if(this.presenceActive) {
      setTimeout(() =>
        this.$buefy.toast.open({
          message: 'Member joined',
          position: 'is-bottom-right',
          type: 'is-black',
        }), 500);
    } else {
      setTimeout(() =>
        this.$buefy.toast.open({
          message: 'Member left',
          position: 'is-bottom-right',
          type: 'is-black',
        }), 500);
    }
  }

  isViewing(userId: string){
    return PusherService.isViewingBoard(userId, this.board.pid);
  }

  hasStatus(status: any) {
    if (status !== null) {
      return status.emoji;
    } else {
      return null;
    }
  }

  toggleMainView() {
    if(this.mainView === 'goals') {
      this.mainView = 'schedule';
    } else {
      this.mainView = 'goals';
    }
  }

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
    });
  }

  openSharedRoute() {
    this.$router.push({ name: 'board-shared' });
  }

  openSpotlight() {
    this.$buefy.modal.open({
      component: GuidedCreateObjective,
      props: {
        startDateFromSprint: this.startDateToday,
        inModal: true,
        focusOnMount: true
      },
      events: {
        'objective-created': (objective: ObjectiveObject) => {
          this.addObjectiveToList(objective);
        }
      },
      // @ts-ignore
      customClass: 'spotlight',
      parent: this,
    });
  }


  addObjectiveToList(updatedObjective: ObjectiveObject): void {
    if (!updatedObjective.deleted_at) {
      this.objectivesList = this.addObjective(updatedObjective, this.objectivesList)
    } else {
      this.objectivesList = this.removeObjective(updatedObjective, this.objectivesList)
    }
  }

  drag(data: any): void {
    if ('containerName' in data && data.addedIndex != null) {
      if(data.containerName == 'dateRange') {
        this.dragInDateRange = true;
      }else{
        this.dragInDateRange = false;
      }
      this.updateObjective([data.payload]);
    }
    if(this.$route.query.step === '2') {
      this.$emit('onboarding-activity-added', true);
    }
  }

  getRealIndex(index: any, objective: ObjectiveObject[]): number {
    let locallyObjective = objective[index];
    if (!locallyObjective) {
      return index;
    }

    return this.objectivesList.findIndex((obj: ObjectiveObject) => {
      return obj.id === locallyObjective.id
    })
  }

  getRealDraggedIndex(payloadObj: any): number {
    let locallyObjective = payloadObj;

    return this.objectivesList.findIndex((obj: ObjectiveObject) => {
      return obj.id === locallyObjective.id
    })
  }

  updateObjective(items: ObjectiveObject[]) {
    this.addObjectiveToList(items[0]);
    let updatedItems = new Array();

    items.forEach((item: ObjectiveObject) => {
      updatedItems.push(pick(item, [
        'id',
        'start_date',
        'due_date',
        'order',
      ]));
    })


    return this.$apollo.mutate({
      mutation: UpdateBoardObjectives,
      variables: {
        id: this.board.id,
        objectives: updatedItems,
      },
    }).then((response) => {
      let responseItems = response.data.updateBoardGl.objectives.filter((obj: any) => {
        return obj.id === items[0].id;
      })

      this.addObjectiveToList(responseItems[0]);
      this.$forceUpdate();
      if(this.dragInDateRange) {
        this.$events.fire('dragged-in-date-range');
      }
      return response;
    }).catch((_error) => {

    });
  }

  /**
   * Re-index order so always starting from 0
   */
  reindexOrder(objectives: ObjectiveObject[]): void {
    objectives.forEach((objective: ObjectiveObject, index: number) => {
      let clone = Object.assign({}, objective)
      clone.order = index;

      this.objectivesList = this.addObjective(clone, this.objectivesList)
    });

  }

  reindexOrderContainer(objectives: ObjectiveObject[]): void {
    let updateObjective = new Array();

    objectives.forEach((objective: ObjectiveObject, index: number) => {
      let clone = Object.assign({}, objective)
      clone.order = index;
      this.objectivesList = this.addObjective(clone, this.objectivesList)

      updateObjective.push(clone)
    });
    this.updateObjective(updateObjective);
  }

  get thisYears() {
    let objectives = [];
    for (let i = 0; i < this.getValidObjectives().length; i++) {
      if (isThisYear(new Date(this.getValidObjectives()[i].due_date)) && !isThisWeek(new Date(this.getValidObjectives()[i].due_date))) {
        objectives.push(
          this.getValidObjectives()[i]
        );
      }
    }
    return objectives;
  }

  get thisWeeks() {
    let objectives = ObjectiveCategoryService.getWeeks(this.getValidObjectives());
    return sortBy(objectives, ['order']);
  }

  get thisDays() {
    let objectives = ObjectiveCategoryService.getToday(this.getValidObjectives());

    return sortBy(objectives, ['order']);
  }

  get thisScheduled() {

    let filterObjectives = ObjectiveCategoryService.getScheduled(this.getValidObjectives());
    return sortBy(filterObjectives, ['order']);
  }

  get thisUnScheduled() {
    return sortBy(ObjectiveCategoryService.getUnscheduled(this.getValidObjectives()),['order']);
  }

  getValidObjectives(): ObjectiveObject[] {
    let objectives = this.objectivesList;
    if (this.startDateSelected && this.endDateSelected) {
      objectives = objectives.filter((obj: any) => {
        return new Date(obj.start_date).getTime() >= this.startDateSelected!.getTime() &&
          new Date(obj.due_date).getTime() <= this.endDateSelected!.getTime();
      });
    }

    if (this.statusSelected != 'all') {
      objectives = ObjectiveFilterService.filterObjectiveList('status', this.statusSelected, objectives);
    }
    if (this.ownerSelected != 'all') {
      objectives = ObjectiveFilterService.filterObjectiveList('owner.id', this.ownerSelected, objectives);
    }
    if (this.prioritySelected != 'all') {
      objectives = ObjectiveFilterService.filterObjectiveList('priority', this.prioritySelected, objectives);
    }
    if (this.objectiveTypeSelected != 'all') {
      objectives = ObjectiveFilterService.filterObjectiveList('objective_type.id', this.objectiveTypeSelected, objectives);
    }
    if (this.objectiveTagSelected != 'all') {
      objectives = ObjectiveFilterService.filterObjectiveFromArray('tags','id', this.objectiveTagSelected, objectives);
    }
    if (this.recurringSelected) {
      objectives = ObjectiveFilterService.filterObjectiveList('is_recurring', this.recurringSelected, objectives);
    }

    return objectives;
  }

  removeObjective(updatedObjective: ObjectiveObject, objectives: ObjectiveObject[]): ObjectiveObject[] {
    objectives = objectives.filter((eObjective: ObjectiveObject) => {
      return eObjective.id !== updatedObjective.id
    })
    return sortBy(objectives, ['order'])
  }

  addObjective(updatedObjective: ObjectiveObject, objectives: ObjectiveObject[]): ObjectiveObject[] {
    let index = objectives.findIndex((eObjective: ObjectiveObject) => {
      return eObjective.id === updatedObjective.id
    })

    if (index !== -1) {
      objectives[index] = updatedObjective;
    } else {
      objectives.unshift(updatedObjective);
    }
    return sortBy(objectives, ['order'])
  }

  mounted() {
    this.$events.listen('filter-status', eventData => this.statusSelected=eventData);
    this.$events.listen('filter-recurring', () => this.recurringSelected = !this.recurringSelected);
    this.$events.listen('filter-priority', eventData => this.prioritySelected = eventData);
    this.$events.listen('filter-objective-type', eventData => this.objectiveTypeSelected = eventData);
    this.$events.listen('filter-owner', eventData => this.ownerSelected = eventData);
    this.$events.listen('filter-tag', eventData => this.objectiveTagSelected = eventData);
    this.$events.listen('filter-date-range', (eventData) => {

      this.startDateSelected = eventData.startDate;
      this.endDateSelected = eventData.endDate;

    });
    this.$events.listen('filter-reset', () => {
      this.statusSelected = 'all';
      this.ownerSelected = 'all';
      this.prioritySelected = 'all';
      this.objectiveTypeSelected = 'all';
      this.recurringSelected = false;
      this.startDateSelected = null;
      this.endDateSelected = null;
      this.objectiveTagSelected = 'all';

    });
    this.$events.listen('north-star-delete', (eventData)=>{
      //@ts-ignore
      this.board.northStars = this.board!.northStars!.filter((northstar)=>{
        if(northstar.id === eventData){
          return;
        }
        return northstar;
      })

    })

    this.$events.listen('objective-deleted', (eventData => {
      this.addObjectiveToList(eventData);
    }))

    this.$events.listen('objective-created', (eventData => {

      this.addObjectiveToList(eventData);

    }))

    this.$events.listen('open-objective', (eventData => {
      if(!('from_northstar' in eventData) ){
        this.openObjectiveDetailed(eventData.pid);
      }

    }));

    this.$events.listen('objective-updated', (eventData => {

      this.addObjectiveToList(eventData);
    }))

    if(this.$store.state.notification_objective_pid) {
      let objecvtive = this.objectivesList.filter((objective: any) => {
        return objective.pid === this.$store.state.notification_objective_pid;
      })
      if(objecvtive) {
        this.openObjectiveDetailed(this.$store.state.notification_objective_pid);
      }else{
        this.isAllObjectiveLoaded = true;
        this.getObjectivesData('upcomming');
        this.getObjectivesData('due');
        this.openObjectiveDetailed(this.$store.state.notification_objective_pid);
      }

      this.$store.commit('set_notification_objective_id', null);
    }

    this.$events.listen('load-all-objectives', ()=>{
      this.isAllObjectiveLoaded = true;
      this.getObjectivesData('upcomming');
      this.getObjectivesData('due');
    })

    this.$events.listen('load-ongoing-objectives', () => {
      this.getObjectivesData('ongoing');
    })

    this.$events.listen('northstar-linked-to-objective', () => {
      if(this.isAllObjectiveLoaded) {
        this.getObjectivesData('upcomming');
        this.getObjectivesData('due');

        this.$events.fire('all-objectives-loaded')

      }else{
        this.getObjectivesData('ongoing');
      }

    })

    this.$events.listen('open-objective-from-notification', (objectivePid: any) => {
      this.openObjectiveDetailed(objectivePid);
    })

    this.$events.listen('load-more-objectives', (eventsData: any) => {
      if(eventsData.workspaceLevel === false) {
        if(eventsData.category == 'ongoing') {
          this.ongoingPagination++;
          this.getObjectivesData('ongoing');
        }
        if(eventsData.category == 'upcomming') {
          this.upcommingPagination++;
          this.getObjectivesData('upcomming');

        }
        if(eventsData.category == 'due') {
          this.duePagination++;
          this.getObjectivesData('due');

        }
      }
    })


    this.$events.listen('set-objective-recurrence', eventData => {
      this.getValidObjectives().map((obj: ObjectiveObject) => {
        if(obj.id === eventData.objective_id) {
          obj.is_recurring = true;
          obj.start_date = eventData.start_date;
          return obj;
        }
      })
    })

    this.$events.listen('delete-objective-recurrence', eventData => {
      this.getValidObjectives().map((obj: ObjectiveObject) => {
        if(obj.id === eventData.objective_id) {
          obj.is_recurring = false;
          return obj;
        }
      })
    })


    if(this.$route.params.obj_pid){
      if(this.currentObjectiveId != this.$route.params.obj_pid ) {
        this.currentObjectiveId = this.$route.params.obj_pid;
        this.openObjectiveDetailed(this.currentObjectiveId);
      }
    }




  }

  getObjectivesData(cat: string) {
    let page = 0;
    if(cat == 'ongoing') {
      //@ts-ignore
      page = this.ongoingPagination
    }else if(cat == 'upcomming') {
      //@ts-ignore
      page = this.upcommingPagination;
    }else if(cat == 'due') {
      //@ts-ignore
      page = this.duePagination;
    }

    this.$apollo.query({
      query: ObjectivesListQuery,
      fetchPolicy: 'network-only',
      variables:{
        filter_type: "board",
        filter_type_id: this.board.id,
        filter_type_category: cat,
        status: 'not_completed',
        page: page
      }
    }).then((result) => {
      if (result && result.data && result.data.getObjectivesLists.data) {
        result.data.getObjectivesLists.data.forEach((objective: ObjectiveObject) => {
          this.addObjectiveToList(objective)
        })

        this.$forceUpdate();
        this.$events.fire('has-more-pages-in-objective-'+cat, {category: cat, hasMorePages: result.data.getObjectivesLists.paginatorInfo.hasMorePages})

      }
    })
  }

  openObjectiveDetailed(objectivePid: string) {

    PusherService.objectiveSubscription(objectivePid);

    let modal = this.$buefy.modal.open({
      component: ObjectiveDetailed,
      props: {
        objectivePid: objectivePid
      },
      events: {
        'objective-updated': (objective: ObjectiveObject) => {
          this.$emit('objective-updated', objective)
          // if(this.isAllObjectiveLoaded) {
          //   this.getObjectives('all');
          // }else{
          //   this.getObjectives('ongoing');
          // }

        }
      },
      // @ts-ignore
      customClass: 'objective-modal',
      parent: this,
      animation: 'none',
    });

    modal.$on("close", () => {
      PusherService.unsubscribeObjectiveSubscription(objectivePid);
      // if(this.$route.params.obj_id) {
      //   this.$router.replace('/'+this.workspace.pid+'/'+this.boardPid+'/overview/settings')
      // }
    });

  }

  beforeDestroy () {
    this.$events.$off('open-objective')
  }


  clickBack() {
    this.currentTab = null;
    this.$events.fire('chat-opened', {board_id:this.board.id,opened: false});
  }

  @Watch('$store.state.board', {immediate: true})
  boardChanged() {
    if (this.board) {

      let index = this.board.members?.findIndex((member: any)=>{ return member.user.id === this.$store.state.me.id });
      if(index != -1){
        this.isMember = true;
      }else {
        this.isMember = false;
      }
      this.$events.fire('is-board-member', this.isMember);
    }
  }
}
