



































































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  add,
  addMonths,
  addQuarters,
  addWeeks,
  addDays,
  endOfISOWeek,
  endOfISOWeekYear, endOfMonth, endOfQuarter, endOfYear,
  getISOWeek, getMonth, getWeek,  getDay, getQuarter, getYear, isBefore, startOfISOWeek,
  startOfISOWeekYear, startOfMonth, startOfQuarter, startOfYear, sub, subQuarters,
  startOfDay, endOfDay, getDayOfYear, isYesterday, isTomorrow, format
} from "date-fns";
import {CreateBoardFromTemplateMutation} from "@/graphql/board";
import {WorkspaceQuery} from "@/graphql/workspace";
import {Objective as ObjectiveObject} from "@/typescript/types";
import PusherService from "@/PusherService";
import CuratedGoalsSelector from "@/components/templates/CuratedGoalsSelector.vue";
import ObjectiveDetailed from "@/components/board/objective/ObjectiveDetailed.vue";
import VerifyUser from '../auth/VerifyUser.vue';
import AddGoalsFromTemplate from "@/components/templates/AddGoalsFromTemplate.vue";
import InRoomActivation from "@/components/auth/register/InRoomActivation.vue";
import Paywall from "@/components/subscription/Paywall.vue";

const DashboardCompletedNorthstar = () => import("@/components/MasterDashboardWidgets/DashboardCompletedNorthstar.vue")
const DashboardCompletedObjectives = () => import("@/components/MasterDashboardWidgets/DashboardCompletedObjectives.vue")
const ObjectiveLists = () => import("@/components/objectives/ObjectiveLists.vue")
const DashboardReminder = () => import("@/components/MasterDashboardWidgets/DashboardReminder.vue")
const MyNorthstars  = () => import("@/components/workspace/MyNorthstars.vue")
const Template  = () => import("@/components/templates/Template.vue")
const DueObjectives  = () => import("@/components/objectives/DueObjectives.vue")
const ObjectivesNotLinkedToNorthstar  = () => import("@/components/objectives/ObjectivesNotLinkedToNorthstar.vue")
const  WeeklyEngagement  = () => import("@/components/workspace/statistics/WeeklyEngagement.vue")
const  FilterDropdown  = () => import("@/components/MasterDashboardWidgets/FilterDropdown.vue")
const  GanttContainer  = () => import("@/components/gantt/GanttContainer.vue")
const  AddANorthstarOrTemplate = () => import("@/components/workspace/AddANorthstarOrTemplate.vue")




@Component({
  components: {
    GanttContainer,
    FilterDropdown,
    WeeklyEngagement,
    DueObjectives,
    Template,
    MyNorthstars,
    DashboardReminder, 
    ObjectiveLists, 
    DashboardCompletedNorthstar, 
    DashboardCompletedObjectives, 
    ObjectivesNotLinkedToNorthstar,
    AddANorthstarOrTemplate}
})
export default class MasterDashboard extends Vue {

  editPeriodicityActive: boolean = false;
  previousWeekArray: Array<any> = [];
  previousDayArray: Array<any> = [];
  dayArray: Array<any> = [];  
  weekArray: Array<any> = [] ;
  quarterArray: Array<any> = [];
  monthArray: Array<any> = [];
  yearArray: Array<any> = [];
  currentDay: number = getDayOfYear(new Date());
  currentWeek: number = getISOWeek(new Date());
  currentQuarter: number = getQuarter(new Date())
  currentMonth: number = getMonth(new Date())
  startOfDate: Date | null = null;
  endOfDate: Date | null = null;
  weekRange: string = "";
  selectedPeriodNumber: number | null = null;
  currentYaer: number = getYear(new Date());
  yearlyView: boolean = false;
  activeNumber: number = 0;
  selectedUser: string = 'all';
  onbaordingLoad: number = 0;
  showPreviousWeekButton: boolean = true;
  showPreviousDaysButton: boolean = true;
  showUnassigned: boolean = true;
  showDue: boolean = false;
  highlightObjectiveCreate: boolean = false;
  addAGoalOpen: boolean = false;
  periodicity: Array<string> = [
    'daily', 'weekly', 'monthly', 'quarterly', 'yearly'
  ]
  selectedPeriodicity: string = 'monthly';
  currentMainView: string | null = null;
  giveSuggestions: boolean = false;
  showCompletedLists: boolean = false;
  scrollPosition: number | any = null;
  minimizedStatistics: boolean = true;
  showNowButton: boolean = false;
  confettiOrigin: { x: number, y: number } = { x: 0, y: 0 };
  northstarInputOpen: boolean = false;

  @Prop({required: true})
  origin!: string

  @Prop({required: true})
  originId!: string

  @Watch ( '$route', { immediate: true, deep: true })
  onRouteChange(){
    const mainArea = document.querySelector('.main_work_area') as HTMLInputElement;
    if(mainArea && mainArea.scrollTop > 0) {
      mainArea.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }



  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get isMemberOfWorkspace() {
    let index = this.workspace.allMembers?.findIndex((member: any)=>{ return member.user.id === this.$store.state.me.id && member.role === 'member' });
    if(index != -1){
      return true;
    }
    return false;

  }

  get hasFreemiumAccess() {
    return this.me.has_freemium_access;
  }

  get hasAccessToAllPeriods() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.all_timeline_period_access) || this.hasFreemiumAccess;
  }

  get keymap() {
    return {
      // 'esc+ctrl' is OK.  - OBSERVE: don't add parenthesis at the end of the function triggered.. This messes things up.
      'meta+i': this.nextDateRange,
      'meta+u': this.prevDateRange,
    }
  }

  get currentView() {
    return this.$store.state.dashboard_view;
  }

  get weekTitle() {   
    if (this.selectedPeriodNumber === this.currentWeek && this.selectedPeriodicity === 'weekly') {
      return 'This week'; 
    } else if (this.selectedPeriodNumber === this.currentWeek + 1 && this.selectedPeriodicity === 'weekly'){    
      return 'Next week'; }
    else if (this.selectedPeriodNumber === this.currentWeek - 1 && this.selectedPeriodicity === 'weekly'){ 
      return 'Last week';
    } else { return "Week"}
  }

  get isCurrentWeek() {
    return this.selectedPeriodNumber === this.currentWeek && this.selectedPeriodicity === 'weekly';
  }

  get isNextWeek() {
    return this.selectedPeriodNumber === this.currentWeek + 1 && this.selectedPeriodicity === 'weekly';
  }

  get isLastWeek() {
    return this.selectedPeriodNumber === this.currentWeek - 1 && this.selectedPeriodicity === 'weekly';
  }

  get isToday() {
    return this.selectedPeriodNumber === getDayOfYear(new Date()) && this.selectedPeriodicity === 'daily';
  }

  // Check if the selected date is yesterday
  get isYesterday() {
    if (!this.startOfDate) return false;
    return isYesterday(this.startOfDate);
  }

  // Check if the selected date is tomorrow
  get isTomorrow() {
    if (!this.startOfDate) return false;
    return isTomorrow(this.startOfDate);
  }

  get dayTitle() {
    if (this.isToday) return 'Today';
    if (this.isTomorrow) return 'Tomorrow';
    if (this.isYesterday) return 'Yesterday';
    
    // Format the date as "Day of month / month" for other dates
    return this.startOfDate ? format(this.startOfDate, 'MMM d') : '';
  }

  get isThisYear() {
    return this.currentYaer === getYear(new Date());
  }

  get isCreateGoalOpen() {
    return this.$store.state.add_goal_open;
  }

  get onboarding() {
    return this.$store.state.expanded_onboarding;
  }

  get currentlyOnboarding() {
    return !!(this.$store.state.currently_onboarding || this.$store.state.currently_onboarding_in_room);
  }

  get showCreateGoalTooltip() {
    return this.$store.state.create_goal_tooltip;
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  // get canConvertToTemplate() {
  //   return this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.all_timeline_periods_access
  // }

  handleOpenCreateGoalInput() {
    if(!this.isCreateGoalOpen) {
      this.$store.commit('set_add_goal_open', true);
    } else {
      this.$store.commit('set_add_goal_open', false);
    }
  }

  prettyPeriodName(name: string) {
    if(name === 'weekly') {
      return 'week';
    } else if (name === 'daily') {
      return 'day';
    } else if(name === 'monthly') {
      return 'month';
    } else if(name === 'quarterly') {
      return 'quarter';
    } else if(name === 'yearly') {
      return 'year'
    } else {
      return 'period'
    }
  }

  setSelectedUser(data: string) {
    if(data != undefined) {
      this.selectedUser = data;
    }else{
      this.selectedUser = 'all';

    }
  }

  getYears() {
    let start_year = 2023;
    for(start_year; start_year <= getYear(new Date()) + 10; start_year++) {
      this.yearArray.push(start_year)
    }
    
  }

  resetData() {
    this.currentYaer = getYear(new Date());
    if(this.selectedPeriodicity == 'weekly') {
      this.getWeekData(new Date(), this.currentWeek);
      this.showNowButton = false;

    } else if(this.selectedPeriodicity == 'daily') {
      const today = new Date();
      this.getDayData(today, getDayOfYear(today));
      this.showNowButton = false;
    }
    else if(this.selectedPeriodicity == 'monthly') {
      this.getMonthData(new Date(), this.currentMonth);
      this.showNowButton = false;

    }else if(this.selectedPeriodicity == 'quarterly') {
      this.getQuarterData(new Date(), this.currentQuarter);
      this.showNowButton = false;

    }else if(this.selectedPeriodicity == 'yearly') {
      this.getYearData(this.currentYaer);
      this.showNowButton = false;

    }
  }
  /**
   * Year calculation starts
   * */
  getYearData(year: number) {
    this.startOfDate = startOfYear(new Date(year, 1, 1));
    this.endOfDate = endOfYear(new Date(year, 1, 1));
    this.selectedPeriodNumber = year;
  }



  /**
   * Week calculation starts
   * */

  weeksOfYearData(year: number) {
    let startOfWeek = startOfISOWeekYear(new Date(year, 1, 1))
    let endOfWeekYear = endOfISOWeekYear(new Date(year, 1, 1))

    for (let i = startOfWeek; isBefore(i, endOfWeekYear); i = addWeeks(i, 1)) {
      if(getISOWeek(i) < this.currentWeek) {
        this.previousWeekArray.push({date: i, weekNo: getISOWeek(i)})
      }else{
        this.weekArray.push({date: i, weekNo: getISOWeek(i)})
      }

    }

    this.getWeekData(this.weekArray[0].date, this.currentWeek);
  }

  addPreviousWeeks()
  {
    this.weekArray = [...this.previousWeekArray, ...this.weekArray];
    this.showPreviousWeekButton = false;
  }

  addPreviousDays()
  {
    this.dayArray = [...this.previousDayArray, ...this.dayArray];
    this.showPreviousDaysButton = false;
  }

 

  getWeekData(weekDate: Date, weekNumber: number) {
   
    this.startOfDate = startOfISOWeek(weekDate);
    this.endOfDate = endOfISOWeek(weekDate);
    this.selectedPeriodNumber = weekNumber;
    this.yearlyView = false;
   

    this.weekRange = format(this.startOfDate, 'MMM dd') + ' - ' + format(this.endOfDate, 'MMM dd');

    if(weekNumber != this.currentWeek) {
      this.showNowButton = true

    }else{
      this.showNowButton = false

    }

  }

  daysOfYearData(year: number) {
    const startOfYearDate = startOfYear(new Date(year, 0, 1)); // Note: JavaScript months are 0-indexed
    const endOfYearDate = endOfYear(new Date(year, 0, 1));

    for (let i = startOfYearDate; isBefore(i, endOfYearDate); i = addDays(i, 1)) {
      if (getDayOfYear(i) < this.currentDay) {
        this.previousDayArray.push({ date: i, dayNo: getDayOfYear(i) });
      } else {
        this.dayArray.push({ date: i, dayNo: getDayOfYear(i) });
      }
    }

    this.getDayData(this.dayArray[0].date, this.currentDay);
  }



  getDayData(date: Date, dayNumber: number | null = null) {
    const adjustedDate = new Date(this.currentYaer, date.getMonth(), date.getDate());
    this.startOfDate = startOfDay(adjustedDate);
    this.endOfDate = endOfDay(adjustedDate);
    this.selectedPeriodNumber = dayNumber
    if(dayNumber != this.currentDay) {
      this.showNowButton = true

    }else{
      this.showNowButton = false

    }
  }


  /**
   * Quarter calculation starts
   * */

  quarterOfYearData(year: number) {
    let startOfQuarterYear = startOfYear(new Date(year, 1, 1))
    let endOfQuarterYear = endOfYear(new Date(year, 1, 1))
    // if(this.quarterArray.length < 0) {
    for(let i = startOfQuarterYear; isBefore(i, endOfQuarterYear); i = addQuarters(i, 1)) {
      this.quarterArray.push({date: i, quarterNo: getQuarter(i)})
    }
    // }
    let index = this.quarterArray.findIndex((item) => {
      return item.quarterNo === this.currentQuarter;
    })

    if(index != -1) {
      this.getQuarterData(this.quarterArray[index].date, this.currentQuarter);
    }


  }

  getQuarterData(quarterDate: Date, quarterNumber: number) {
    this.startOfDate = startOfQuarter(quarterDate);
    this.endOfDate = endOfQuarter(quarterDate);
    this.selectedPeriodNumber = quarterNumber;
    this.yearlyView = false;
    if(quarterNumber != this.currentQuarter) {
      this.showNowButton = true;
    }else{
      this.showNowButton = false;
    }
  }

  /**
   * Monthly calculation starts
   * */

  monthOfYearData(year: number) {
    let startOfMonthYear = startOfYear(new Date(year, 1, 1));
    let endOfMonthYear = endOfYear(new Date(year, 1, 1));

    for(let i = startOfMonthYear; isBefore(i, endOfMonthYear); i = addMonths(i, 1)) {
      this.monthArray.push({date: i, monthNo: getMonth(i)})
    }


    let index = this.monthArray.findIndex((item) => {
      return item.monthNo === this.currentMonth;
    })

    if(index != -1) {
      this.getMonthData(this.monthArray[index].date, this.currentMonth)
    }
  }

  getMonthData(monthDate: Date, monthNumber: number) {
    this.startOfDate = startOfMonth(monthDate);
    this.endOfDate = endOfMonth(monthDate);
    this.selectedPeriodNumber = monthNumber;
    this.yearlyView = false;

    if(monthNumber != this.currentMonth) {
      this.showNowButton = true;
    }else{
      this.showNowButton = false
    }
  }



  allYear() {
    this.startOfDate = startOfISOWeekYear(new Date(this.currentYaer, 1, 1))
    this.endOfDate = endOfISOWeekYear(new Date(this.currentYaer, 12, 31))
    this.selectedPeriodNumber = null;
    this.yearlyView = true;
  }

  switchView(view: string | null) {
    this.$store.commit('set_dashboard_view', view);
    this.$store.commit('set_total_objectives_in_timeStamp', null);
  }

  backToCurrentWeek() {
    if(this.weekArray.length >= 52) {
      this.getWeekData(this.weekArray[this.currentWeek - 1].date, this.currentWeek)
    }else{
      this.getWeekData(this.weekArray[0].date, this.currentWeek)
    }
  }

  handlePeriodClicked(period: Date) {

    // Get the new start and end of the month for the clicked period
    const newStartOfMonth = startOfMonth(period);
    const newEndOfMonth = endOfMonth(period);
  
    // Check if the month is different from the currently selected period
    if (!this.startOfDate || newStartOfMonth.getMonth() !== this.startOfDate.getMonth() || newStartOfMonth.getFullYear() !== this.startOfDate.getFullYear()) {
   
      // Update the start and end dates to the new month
      this.startOfDate = newStartOfMonth;
      this.endOfDate = newEndOfMonth;

      const year = getYear(period);

      //Check which period selected period is within
      switch (this.selectedPeriodicity) {
        case "daily":
          this.getDayData(this.startOfDate, getDay(this.startOfDate));
          break;
        case "weekly":
          this.getWeekData(this.startOfDate, getWeek(this.startOfDate));
          break;
        case "quarterly":
          this.startOfDate = startOfQuarter(period); 
          this.endOfDate = endOfQuarter(period); 
          this.getQuarterData(this.startOfDate, getQuarter(this.startOfDate));
          break;
        case "monthly":
          this.getMonthData(period, getMonth(period));
          break;
        case "yearly":
          this.startOfDate = startOfYear(new Date(year, 0, 1)); 
          this.endOfDate = endOfYear(new Date(year, 11, 31)); 
          this.getYearData(year); 
          break;
      }
    } 
  }

  createFromTemplate() {
    this.$apollo
      .mutate({
        mutation: CreateBoardFromTemplateMutation,
        variables: {
          workspace: this.workspace.id,
          template_id: this.$store.state.autoGenUserData.template_id,
          permissions: 'workspace'
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
      })
      .then(({data}) => {
        this.$store.commit('set_is_social_login_with_template', false);
        this.$router.push({name: "onboarding-view", params: {workspace_pid: this.workspace.pid, board_pid: data.createBoardFromTemplate.pid}})
      })
    ;
  }

  // addNorthstar() {
  //   this.$buefy.modal.open({
  //     component: AddOrLinkNorthStar,
  //     props: {
  //       origin: (this.$store.state.board) ? 'board' : 'workspace',
  //       inModal: true,
  //       startDate: this.startOfDate
  //     },
  //     width: '480px',
  //     // @ts-ignore
  //     parent: this,
  //   });
  // }

  toggleNorthstarInput() {
    if(this.showCreateGoalTooltip) {
      this.$store.commit('set_create_goal_tooltip', null);
    }
    this.northstarInputOpen = !this.northstarInputOpen
  }



  openAddNorthStar() {
    this.$buefy.modal.open({
      component: AddGoalsFromTemplate,
      //@ts-ignore
      customClass: 'add_goal_from_template',
      parent: this,
      props: {
        startDate: this.startOfDate
      }
    });
  }

  scrollDateSelector() {
    const chatWindow = document.querySelector('.vertical_date_body') as HTMLInputElement;
    if(chatWindow) {
      chatWindow.scrollTop = 64;
    }
  }

  yearChange() {
    this.checkChange();
  }

  next() {
    if(this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.template_id != '') {
      this.$router.push({name: "onboarding-view", params: {workspace_pid: this.workspace.pid, board_pid: this.workspace.boards[0].pid}})
    }else{
      this.$router.push({name: 'create-board', params: { workspace_pid: this.workspace.pid } })
    }
  }

  changePeriodicity(periodicity: string) {
    if((periodicity === 'quartely' || 'yearly') && !this.hasAccessToAllPeriods) {
      this.openPaywall();
    } else {
      this.selectedPeriodicity = periodicity;
      this.checkChange();
    }
  }

  checkChange() {
    const today = new Date(); // Get today's date
    const dayNumber = getDayOfYear(today);
    switch(this.selectedPeriodicity) {
      case "daily":
        this.getDayData(today, dayNumber); // Update the daily view to show "Today"
        this.daysOfYearData(this.currentYaer);
        this.$store.commit('set_dashboard_view_periodicity', 'daily');
        this.$store.commit('set_dashboard_view_periodicity', 'daily');
        this.showNowButton = false;
        break;
      case "weekly":
        this.weekArray = [];
        this.weeksOfYearData(this.currentYaer);
        this.$store.commit('set_dashboard_view_periodicity', 'weekly');
        break;
      case "quarterly":
        this.quarterArray = [];
        this.quarterOfYearData(this.currentYaer);
        this.$store.commit('set_dashboard_view_periodicity', 'quarterly');
        break;
      case "monthly":
        this.monthArray = [];
        this.monthOfYearData(this.currentYaer);
        this.$store.commit('set_dashboard_view_periodicity', 'monthly');
        break;
      case "yearly":
        this.getYearData(this.currentYaer);
        this.$store.commit('set_dashboard_view_periodicity', 'yearly');
        break;
    }
  }

  prevDateRange() {

    this.$events.fire('goals-fade-right');
    switch(this.selectedPeriodicity) {
      case "daily": {
        let previousDayDate = sub(this.startOfDate!, { days: 1 });
        let previousDayNo = getDayOfYear(previousDayDate);
        this.currentDay = getDayOfYear(new Date());
        this.getDayData(previousDayDate, previousDayNo);
        
        if(this.currentDay != previousDayNo) {
          this.showNowButton = true;
        }else{
          this.showNowButton = false;
        }
        break;
      }
      case "weekly": {
        let previousWeekDate = sub(this.startOfDate!, {weeks: 1});
        let previousWeekNo = getISOWeek(previousWeekDate);
        this.currentYaer = getYear(previousWeekDate);
        this.getWeekData(previousWeekDate, previousWeekNo);
        if(this.currentWeek != previousWeekNo) {
          this.showNowButton = true;
        }else{
          this.showNowButton = false;
        }
        break;
      }
      case "monthly": {
        let previousMonthDate = sub(this.startOfDate!, {months: 1});
        let previousMonthNo = getMonth(previousMonthDate);
        this.currentYaer = getYear(previousMonthDate);
        this.getMonthData(previousMonthDate, previousMonthNo);
        if(this.currentMonth != previousMonthNo) {
          this.showNowButton = true;
        }else{
          this.showNowButton = false;
        }
        break;
      }
      case "quarterly": {
        let previousQuarterDate = subQuarters(this.startOfDate!, 1);
        let previousQuarterNo = getQuarter(previousQuarterDate);
        this.currentYaer = getYear(previousQuarterDate);
        this.getQuarterData(previousQuarterDate, previousQuarterNo);
        if(this.currentQuarter != previousQuarterNo) {
          this.showNowButton = true;
        }else{
          this.showNowButton = false;
        }
        break;
      }
      case "yearly": {
        this.currentYaer -= 1;
        if(!this.yearArray.includes(this.currentYaer)) {
          this.yearArray.unshift(this.currentYaer);
        }
        this.getYearData(this.currentYaer);
        if(this.currentYaer != getYear(new Date())) {
          this.showNowButton = true;
        }else{
          this.showNowButton = false;
        }
        break;
      }


    }

  }

  nextDateRange() {
  
    this.$events.fire('goals-fade-left');

    switch(this.selectedPeriodicity) {
      case "daily": {
        let nextDayDate = add(this.startOfDate!, { days: 1 });
        let nextDayNo = getDayOfYear(nextDayDate);
        this.currentDay = getDayOfYear(new Date());
        this.getDayData(nextDayDate, nextDayNo);
        if(this.currentDay != nextDayNo) {
          this.showNowButton = true;
        }else{
          if(this.currentYaer != getYear(new Date())) {
            this.showNowButton = true;
          }else{
            this.showNowButton = false;
          }
          //this.showNowButton = false;
        }
        break;
      }
      case "weekly": {
        let nextWeekDate = add(this.startOfDate!, {weeks: 1});
        let nextWeekNo = getISOWeek(nextWeekDate);
        this.currentYaer = getYear(nextWeekDate);
        this.getWeekData(nextWeekDate, nextWeekNo);
        if(this.currentWeek != nextWeekNo) {
          this.showNowButton = true;
        }else{
          if(this.currentYaer != getYear(new Date())) {
            this.showNowButton = true;
          }else{
            this.showNowButton = false;
          }
          //this.showNowButton = false;
        }
        break;
      }
      case "monthly": {
        let nextMonthDate = add(this.startOfDate!, {months: 1});
        let nextMonthNo = getMonth(nextMonthDate);
        this.currentYaer = getYear(nextMonthDate);
        this.getMonthData(nextMonthDate, nextMonthNo);
        if(this.currentMonth != nextMonthNo) {
          this.showNowButton = true;
        }else{
          if(this.currentYaer != getYear(new Date())) {
            this.showNowButton = true;
          }else{
            this.showNowButton = false;
          }
          //this.showNowButton = false;
        }
        break;
      }
      case "quarterly": {
        let nextQuarterDate = addQuarters(this.startOfDate!, 1);
        let nextQuarterNo = getQuarter(nextQuarterDate);
        this.currentYaer = getYear(nextQuarterDate);
        this.getQuarterData(nextQuarterDate, nextQuarterNo);
        if(this.currentQuarter != nextQuarterNo) {
          this.showNowButton = true;
        }else{
          if(this.currentYaer != getYear(new Date())) {
            this.showNowButton = true;
          }else{
            this.showNowButton = false;
          }
          //this.showNowButton = false;
        }
        break;
      }
      case "yearly": {
        this.currentYaer += 1;
        if(!this.yearArray.includes(this.currentYaer)) {
          this.yearArray.push(this.currentYaer);
        }
        this.getYearData(this.currentYaer);
        if(this.currentYaer != getYear(new Date())) {
          this.showNowButton = true;
        }else{
          this.showNowButton = false;
        }
        break;
      }
    }
  }

  created() {
    if(this.$store.state.autoGenUserData) {
      if(this.$store.state.autoGenUserData && this.$store.state.autoGenUserData.template_id != '' && this.$store.state.isSocialLoginWithTemplate) {
        this.createFromTemplate();
      }

      /*
      this.weeksOfYearData(this.currentYaer);
      // Assuming weeksOfYearData method populates weekArray and sets up the view
      // You might need to call a method similar to getWeekData here if weeksOfYearData doesn't automatically set the view
      this.getWeekData(new Date(), this.currentWeek);

      this.getDayData(new Date(), this.currentDay);

       */
    }
  }

  openObjectiveDetailed(objectivePid: string) {

    PusherService.objectiveSubscription(objectivePid);

    let modal = this.$buefy.modal.open({
      component: ObjectiveDetailed,
      props: {
        objectivePid: objectivePid
      },
      events: {
        'objective-updated': (objective: ObjectiveObject) => {
          this.$emit('objective-updated', objective)
          // if(this.isAllObjectiveLoaded) {
          //   this.getObjectives('all');
          // }else{
          //   this.getObjectives('ongoing');
          // }

        }
      },
      // @ts-ignore
      customClass: 'objective-modal',
      parent: this,
      animation: 'none',
    });

    modal.$on("close", () => {
      PusherService.unsubscribeObjectiveSubscription(objectivePid);
      // if(this.$route.params.obj_id) {
      //   this.$router.replace('/'+this.workspace.pid+'/'+this.boardPid+'/overview/settings')
      // }
    });

  }

  openVerifyUser() {
    
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true,
        signUpWithEmail: true
      },
      width: '500px',
      canCancel: false,
      parent: this,
    });
  }

  openCuratedGoalsSelector() {
    this.$buefy.modal.open({
      component: CuratedGoalsSelector,
      width: '700px',
      parent: this,
      canCancel: false,
      customClass: 'curated_modal'
    });
  }

  openActivationModal() {
    this.$buefy.modal.open({
      component: InRoomActivation,
      //@ts-ignore
      customClass: 'add_goal_from_onboarding',
      canCancel: false,
      parent: this,
    });
  }
 
  mounted() {

    // if(this.templateOnboarding) {
    //   this.openCuratedGoalsSelector();
    // }

    if(this.currentlyOnboarding) {
      this.openActivationModal()
    }

    

    this.getYears();
    if(this.$store.state.dashboard_view_periodicity) {
      this.selectedPeriodicity = this.$store.state.dashboard_view_periodicity;
    }

    this.$nextTick(function () {
      if(this.selectedPeriodicity == 'weekly') {
        this.weeksOfYearData(this.currentYaer);
        //this.getWeekData(this.weekArray[0].date, this.currentWeek);
      } else if(this.selectedPeriodicity == 'daily') {
        this.daysOfYearData(this.currentYaer);
        //this.getWeekData(this.weekArray[0].date, this.currentWeek);
      }
      else if(this.selectedPeriodicity == 'quarterly') {
        this.quarterOfYearData(this.currentYaer);
      }else if(this.selectedPeriodicity == 'monthly') {
        this.monthOfYearData(this.currentYaer)
      }else if(this.selectedPeriodicity == 'yearly') {
        this.getYearData(this.currentYaer)
      }
    });

    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.scrollDateSelector();
    });

    this.$events.fire('should_have_objective_share_url', false);
    this.$events.listen('master-dashboard-completed-objective', (_) => {

      // if(this.selectedPeriodNumber && this.selectedPeriodNumber==this.currentWeek && this.weekArray[0].weekNo == this.currentWeek) {
      //   this.getWeekData(this.weekArray[0].date, this.currentWeek)
      // }else if(this.selectedWeekNumber && this.selectedWeekNumber!=this.currentWeek && this.weekArray[0].weekNo == this.currentWeek){
      //   this.getWeekData(this.weekArray[Math.abs(this.selectedWeekNumber - this.currentWeek)].date, this.currentWeek)
      // }else{
      //   this.getWeekData(this.weekArray[this.currentWeek-1].date, this.currentWeek)
      // }
    })

    if(this.$route.params.objective_pid) {
      this.openObjectiveDetailed(this.$route.params.objective_pid)
    }

    this.$events.listen('open-objective-from-notification', (objectivePid: any) => {
      this.openObjectiveDetailed(objectivePid);
    })

  
  }
}
